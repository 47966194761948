(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name noConnection.controller:NoConnectionCtrl
   *
   * @description
   *
   */
  angular
    .module('noConnection')
    .controller('NoConnectionCtrl', NoConnectionCtrl);

  function NoConnectionCtrl() {
    var vm = this;
    vm.ctrlName = 'NoConnectionCtrl';
  }
}());
